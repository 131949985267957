import styled, { css } from 'styled-components';

const ArtworkContainer = styled.div`
  min-height: calc(100vh - 240px);
  padding: 0 calc((100vw - 1920px) / 2);

  @media all and (max-width: 2340px) {
    padding: 0 210px;
  }

  @media all and (max-width: 1200px) {
    min-height: calc(100vh - 320px);
  }

  @media all and (max-width: 1600px) {
    padding: 0 25px;
  }
`;

const ArtworkAdditionalInfo = styled.div`
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-family: 'Roboto';
  font-weight: 400;
`;

const ArtworkContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto 80px;
  padding-top: 20px;
  min-height: 50vh;
  @media all and (max-width: 800px) {
    min-height: 0;
  }
`;

const ArtworkContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 500;

  a {
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-decoration: none;
    color: #424242;
    font-weight: 300;
    margin-left: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const ActivePageIndexIndicator = styled.span`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #767676;
`;

const TotalPagesIndicator = styled.span`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #b3b3b3;
`;

const ArtworkData = styled.div`
  display: flex;
  flex-direction: row;
  padding: 54px 100px 0 0;
  width: 100%;

  @media all and (max-width: 1200px) {
    padding: 54px 0 0 0;
  }

  @media all and (max-width: 800px) {
    flex-direction: column;
    padding-top: 0;
  }
`;

const ArtworkInfoContainer = styled.div`
  padding: 0 40px 0 0;
  flex: 0 0 380px;

  @media all and (max-width: 800px) {
    padding: 32px 0 0 0;
    flex: 0 0 auto;
  }
`;

const ArtworkInfoButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  &:first-of-type {
    margin-top: 20px;
  }
`;

const primaryButtonStyles = css`
  background-color: #000;
  color: #ffffff;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const secondaryButtonStyles = css`
  background-color: #ffffff;
  color: #000;
`;

const primaryDisabledStyles = css`
  background-color: #4a4a4a;
  border-color: #4a4a4a;
  cursor: default;
  &:hover {
    background-color: #4a4a4a;
    box-shadow: none !important;
  }
`;
const secondaryDisabledStyles = css`
  background-color: #c1c1c1;
  border-color: #414141;
  cursor: default;
  &:hover {
    background-color: #c1c1c1;
    box-shadow: none !important;
  }
`;

const ArtworkInfoButton = styled.button`
  outline: none;
  padding: ${({ size }) =>
    size && size === 'short' ? '10px 20px;' : '10px 30px;'};
  margin-right: 8px;
  cursor: pointer;
  font-weight: 500;
  font-family: Roboto;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #000;
  text-transform: uppercase;
  border: 1px solid #000;
  transition: all 0.2s;
  text-align: center;
  ${(props) =>
    props.type === 'primary' || !props.type ? primaryButtonStyles : null};
  ${(props) =>
    (props.type === 'primary' || !props.type) && props.disabled
      ? primaryDisabledStyles
      : null};
  ${(props) => (props.type === 'secondary' ? secondaryButtonStyles : null)};
  ${(props) =>
    props.type === 'secondary' && props.disabled
      ? secondaryDisabledStyles
      : null};
  text-decoration: none;
  ${(props) => (props.centered ? 'text-align: center;' : null)};

  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.15);
  }

  @media all and (max-width: 800px) {
    flex-grow: 1;
    // flex-grow: ${({ size }) => (size && size === 'short' ? 0 : 1)};
  }

  @media all and (max-width: 480px) {
    padding: 8px 20px;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
`;

const ArtworkBasicInfo = styled.div`
  margin-bottom: 20px;
  font-weight: 300;
  color: #424242;

  @media all and (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

const ArtworkInfoAuthor = styled.h1`
  line-height: 3rem;
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
`;

const ArtworkInfoTitle = styled.h2`
  line-height: 2.6rem;
  font-size: 1.9rem;
  margin-bottom: 12px;
  font-weight: bold;
  font-style: italic;
`;

const ArtworkInfoParagraph = styled.p`
  line-height: 2.1rem;
  font-size: 1.5rem;
  font-weight: 400;
  font-family: 'Roboto';
  margin-bottom: 1.5rem;

  b {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
`;

const ArtworkInfoListHeader = styled.h3`
  margin: 0px;
  text-transform: uppercase;
  line-height: 2.1rem;
  font-size: 1.5rem;
  font-weight: 300;
  color: #3a3a3a;
`;

const ArtworkInfoUnorderedList = styled.ul`
  padding: 0 0 0 8px;
  list-style-type: '- ';
  margin: 0 0 35px 0;
  font-weight: 300;
  color: #555555;

  li {
    line-height: 2.1rem;
    font-size: 1.5rem;
  }
`;

const NextArtworkDesktop = styled.div`
  position: absolute;
  right: 210px;
  top: 50vh;

  img {
    ${(props) => (props.disabled ? 'opacity: 0.3;' : '')};
    width: 30px;
    height: 58px;
  }

  @media all and (max-width: 800px) {
    display: none;
  }

  @media all and (max-width: 1600px) {
    right: 25px;
  }
`;

const PreviousArtworkDesktop = styled.div`
  position: absolute;
  left: 210px;
  top: 50vh;

  img {
    ${(props) => (props.disabled ? 'opacity: 0.3;' : '')};
    width: 30px;
    height: 58px;
  }

  @media all and (max-width: 800px) {
    display: none;
  }

  @media all and (max-width: 1600px) {
    left: 25px;
  }
`;

const NextArtworkMobile = styled.div`
  display: none;

  a {
    line-height: 0;
  }

  img {
    ${(props) => (props.disabled ? 'opacity: 0.3;' : '')};
    width: 30px;
    height: 58px;
  }

  @media all and (max-width: 800px) {
    display: block;
    margin: 0;
    padding: 16px 0 16px 16px;

    img {
      height: 30px;
      width: auto;
    }
  }
`;

const PreviousArtworkMobile = styled.div`
  display: none;

  a {
    line-height: 0;
  }

  img {
    ${(props) => (props.disabled ? 'opacity: 0.3;' : '')};
    width: 30px;
    height: 58px;
  }

  @media all and (max-width: 800px) {
    display: block;
    margin: 0;
    padding: 16px;

    img {
      height: 30px;
      width: auto;
    }
  }
`;

const RightControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PageIndexContainer = styled.div`
  padding-bottom: 2px;
`;

const Field = styled.div`
  label {
    font-size: 13px;
    line-height: 13px;
    padding-top: 20px;
    display: block;
    font-family: inherit;
    ${({ error }) => (error ? 'color: #cd413e;' : null)};
  }
  input {
    width: ${({ size }) => (size === 'short' ? '50%;' : '100%;')};
    height: 36px;
    line-height: 36px;
    font-size: 13px;
    display: block;
    border: 1px solid #ddd;
    padding: 12px;
    margin: 6px 0 2px;
    font-family: inherit;
    ${({ error }) => (error ? 'border-color: #cd413e;' : null)};

    @media all and (max-width: 500px) {
      width: 100%;
    }
  }
  textarea {
    font-weight: normal;
    font-size: 13px;
    display: block;
    width: 100%;
    border: 1px solid #ddd;
    padding: 12px;
    margin: 6px 0;
    min-height: 150px;
    resize: none;
    font-family: inherit;
  }
  .error {
    display: ${({ error }) => (error ? 'block;' : 'none;')};
    font-size: 1.1rem;
    color: #cd413e;
    &#wrong-email {
      display: ${({ email, error }) =>
        error && email && email.length ? 'block;' : 'none;'};
    }
    &#empty-email {
      display: ${({ email, error }) =>
        error && email === '' ? 'block;' : 'none;'};
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 50px;
  background: #fff;
  font-size: 13px;
  line-height: 20px;

  @media all and (max-width: 500px) {
    padding: 25px;
  }

  h2 {
    margin-bottom: 16px;
    font-size: 20px;
    font-family: inherit;
  }

  div {
    font-size: 1.5rem;
    margin: auto;
    padding-top: 2rem;
  }

  .small-btn {
    display: inline-block;
    padding: 8px 12px;
    font-weight: bold;
    color: #666;
    background-color: #fff;
    outline: none;
    cursor: pointer;
    transition: background-color 0.2s;
    position: absolute;
    right: 2rem;
    top: 2rem;
    margin: 0;
    border: none;

    &:hover {
      background-color: #eee;
    }

    @media all and (max-width: 500px) {
      top: 24px;
      right: 12px;
    }
  }

  .action-button {
    border: 2px solid #ccc;
    background: #fff;
    outline: none;
    cursor: pointer;
    font-size: 14px;
    margin: 4px 0;
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.4s all;
    text-align: center;
    display: inline-block;
    width: auto;
    margin-left: auto;
    padding: 12px 20px;
    font-family: inherit;

    &:hover {
      background-color: #eee;
    }
  }
`;

const ErrorMessage = styled.div`
  margin-top: 20px;
  border: 1px solid #cd413e;
  color: #cd413e;
  font-size: 1.4rem;
  padding: 1.6rem;
`;

const SuccessMessage = styled.div`
  margin-top: 20px;
  border: 1px solid #00ad46;
  color: #00ad46;
  font-size: 1.4rem;
  padding: 1.6rem;
`;

const ArtworkInfoPrice = styled.span`
  display: block;
  line-height: 3rem;
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: 2rem;
`;

export {
  ArtworkContainer,
  ArtworkAdditionalInfo,
  ArtworkBasicInfo,
  ArtworkContentContainer,
  ArtworkContentHeader,
  ActivePageIndexIndicator,
  TotalPagesIndicator,
  ArtworkData,
  ArtworkInfoContainer,
  ArtworkInfoAuthor,
  ArtworkInfoTitle,
  ArtworkInfoParagraph,
  ArtworkInfoListHeader,
  ArtworkInfoUnorderedList,
  NextArtworkDesktop,
  PreviousArtworkDesktop,
  NextArtworkMobile,
  PreviousArtworkMobile,
  ArtworkInfoButtonContainer,
  ArtworkInfoButton,
  RightControlsContainer,
  PageIndexContainer,
  Field,
  Form,
  ErrorMessage,
  SuccessMessage,
  ArtworkInfoPrice,
};
